import './StoryItem.scss';

function StoryItem(props: { date: string, title: any, image: any, text: any }) {
  const { date, title, image, text } = props;
  return (
    <div className='our-story-item'>
      <hr />
      <div className='our-story-item__title-date'>
        <span className='our-story-item__title-date__title'>{title}</span>
        <span className='our-story-item__title-date__date'>{date}</span>
      </div>
      <div className='our-story-item__image-desc'>
        <img src={image} alt={title} />
        <p>{text}</p>
      </div>
    </div>
  );
}

export default StoryItem;
