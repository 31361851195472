import './PersonCard.scss';
import { ReactComponent as IconLinkedin } from 'assets/icon-linkedin-2.svg';
import { ReactComponent as IconW } from 'assets/icon-w.svg';

function PersonCard(props: { image?: any, title: any, description?: any, linkedin?: string, wikipedia?: string, className?: string }) {
  const { image, title, description, linkedin, wikipedia, className } = props;

  return (<div className={'person-card ' + (!image && 'disabled ') + ' ' + (className)}>
    {image ? (
    <img src={image} alt="title" />
    ) : (
      <div className='person-card-empty'></div>
    )}
    <h3>{title}</h3>
    <p>{description}</p>
    <div>{linkedin && (<a href={linkedin}><IconLinkedin /></a>)}{wikipedia && (<a href={wikipedia}><IconW className='link-w'/></a>)}</div>
  </div>);
}

export default PersonCard;
