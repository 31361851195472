import PersonCard from 'elements/PersonCard';
import './Team.scss';

function Team() {

  return (<>
    <section>
      <h2 className='team-role-title'><hr /><span>FOUNDERS</span><hr /></h2>
      <div className='team-listing'>
        <PersonCard image={require("assets/noredine.png")} wikipedia="https://fr.wikipedia.org/wiki/Nor%C3%A9dine_Allam" linkedin="https://www.linkedin.com/in/noredineallambdouin/"
          title={<>NOREDINE ALLAM<br />Co Founder - CEO</>}
          description={<>- Artist Author for more than 25 years.<br />- Founder of famous studio 2HB<br />(Dargaud, Astérix, M6 Éditions,<br />Musée Jules Verne…)</>} />
        <PersonCard image={require("assets/rachid.png")} linkedin="https://www.linkedin.com/in/rachid-ouaich-680304a/"
          className="no-right-margin"
          title={<>RACHID OUAICH<br />Co Founder<span> - </span>Investors Relations</>}
          description={<>- Regional M.D. Europe of Wafra Capital Partners (since 2007)<br />- CEO Eethiq Advisors (USD 1.5bn)<br />- Co-founder NEXV & CxC Partners</>} />
      </div>
    </section>
    <section>
      <h2 className='team-role-title'><hr /><span>CORE TEAM</span><hr /></h2>
      <div className='team-listing'>
        <PersonCard title={<>COMING SOON<br />CTO</>} />
        <PersonCard image={require("assets/taha.png")} linkedin="https://www.linkedin.com/in/tahalahbabi/"
          title={<>Taha LAHBABI<br />COO</>}
          description={<>- Former Advisor to the Founding Chairman of Al Baraka Banking Group<br />- Vice-President at the IILM (International Islamic Liquidity Management)</>} />
        <PersonCard image={require("assets/abdelhakim.png")} linkedin="https://www.linkedin.com/in/abdelhakim-atmani-7a577a28/"
          title={<>Abdelhakim ATMANI<br />AWS ARCHITECT</>}
          description={<>AWS architect for<br />Blackcat Technology Solutions<br />- Senior Developer for Mobysoft<br />Senior Developer at Valtech.</>} />

        <PersonCard title={<>COMING SOON<br />BLOCKCHAIN EXPERT</>} />
        <PersonCard title={<>COMING SOON<br />CROSS-PLATFORM MOBILE DEV</>} />
        <PersonCard title={<>COMING SOON<br />STORYTELLING LEAD</>} />
      </div>
    </section>
    <section>
      <h2 className='team-role-title'><hr /><span>ADVISORS</span><hr /></h2>
      <div className='team-listing'>
        <PersonCard title={<>COMING SOON<br />ADVISOR</>} />
        <PersonCard title={<>COMING SOON<br />ADVISOR</>} />
        <PersonCard title={<>COMING SOON<br />ADVISOR</>} />
      </div>
    </section>
  </>);
}

export default Team;
