import './Footer.scss';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as IconLinkedin } from 'assets/icon-linkedin.svg';
import { ReactComponent as IconFacebook } from 'assets/icon-facebook.svg';
import { ReactComponent as IconUp } from 'assets/icon-up.svg';
import { Link } from 'react-router-dom';

function Footer(props: { className?: string }) {

  function topFunction() {
    document.getElementById("main")?.scrollTo({ top: 0, behavior: "smooth" }); // For Safari
  }

  return (
    <>
      <footer className={props.className || ''}>
        <div className='row first-row'>
          <Link to="/"><Logo className='logo' /></Link>
          <ul>
            <li><Link to="/roadmap">Roadmap</Link></li>
            <li><Link to="/ourstory">Our story</Link></li>
            <li>&nbsp;</li>
          </ul>
          <div className='sm-icons'>
            <a href="https://www.linkedin.com/in/noredineallambdouin/"><IconLinkedin /></a>
            <a href="https://www.facebook.com/themuslimshow/"><IconFacebook /></a>
            <IconUp onClick={topFunction} /><span onClick={topFunction}>Up!</span>
          </div>
        </div>
        <div className='row second-row'>
          ©2022.BDouin_op2a
        </div>
      </footer>
    </>
  );
}

export default Footer;
