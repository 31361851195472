import './Roadmap.scss';
import { ReactComponent as BackgroundSection1 } from 'assets/roadmap-background-s1.svg';
import { ReactComponent as BackgroundSection2 } from 'assets/roadmap-background-s2.svg';
import { ReactComponent as RoadmapPath } from 'assets/roadmap-2.svg';
import { ReactComponent as IconEyeExample } from 'assets/icon-eye-example.svg';
import { ReactComponent as Schemas } from 'assets/schemas.svg';
import { ReactComponent as IconClose } from 'assets/icon-close.svg';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function Roadmap() {

  const [openMap, setOpenMap] = useState<boolean>(false);
  const mapRef = useRef<any>();

  const toggleModalMap = () => {
    setOpenMap(!openMap);
    if (!document.body.classList.contains("blockScrolling")) {
      document.body.classList.add("blockScrolling");
    } else {
      document.body.classList.remove("blockScrolling");
    }
  }

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.querySelectorAll('*[id^="FRAME_"]').forEach((frame: any) => {
        frame.onclick = () => {
          const infoFrame = document.getElementById(frame.id + "_INFOS");
          for (let i = 1; i < 18; i++) {
            if (frame.id !== document.getElementById("FRAME_" + i)?.id) {
              document.getElementById("FRAME_" + i + "_INFOS")?.classList.remove("showInfo")
            }
          }
          if (infoFrame && !infoFrame?.classList.contains("showInfo")) {
            infoFrame?.classList.add("showInfo");
          } else {
            infoFrame?.classList.remove("showInfo");
          }
        }
      })
    }
  }, [mapRef]);

  return (<>
    <Modal
      isOpen={openMap}
      style={customStyles}
      contentLabel="NFT Example"
    >
      <IconClose className='close-btn' onClick={toggleModalMap} />
      <img src={require("assets/nft-example.png")} alt="NFT EXAMPLE" className='nft-example' />
    </Modal>
    <div className='roadmap-wrapper'>
      <section className='rm-intro'>
        <BackgroundSection1 />
        <article className='rm-intro-intro-1'>
          <h2>Collaborative Generator</h2>
          <p>Our Generator is an accelerator where ideas from 3 actors fuse into unique scenes, avatars and stickers for the DiNaMus (Digital-Native Muslims) / DiNETech (Diaspora & Native Ethical Techies) to admire and acquire!<br />
            <br />
            1.  The Studio BDouin brings characters, templates and emoticons<br />
            2.  The Pros add their spark, customizing sets or designing additional assets<br />
            3.  The Crea-users combine both with their imagination and with one touch, generate their NFT<br />
          </p>
          <IconEyeExample className='rm-intro-intro-1-example' onClick={toggleModalMap} />
          <span>#Smartcontract #Blockchain #Token</span>
        </article>
        <article className='rm-intro-intro-2'>
          <h2>(Block)chains for good</h2>
          <p>The NFT lands in the Gallery for all users to see, and can be sold on the Marketplace too! <br />
            <br />
            The proceeds will then flow back to each of the creative participants proportionally to the pre-set distribution rates or within pre-agreed fixed terms. <br />
            Royalties and other special rights can also be baked-in the NFT, allowing for a fairer economic model to the creators, and incentivizing collectors to value and showcase their private galleries!<br />
            <br />
            The Generator, where Art & Technology collide for good!
          </p>
          <span>#NFT #CircularEconomy #Share</span>
        </article>
        <Schemas className='schemas' />
      </section>
      <section className='rm-roadmap'>
        <BackgroundSection2 className='rm-roadmap-bkp' />
        <RoadmapPath ref={mapRef} className='rm-roadmap-path' />
      </section>
    </div>
  </>);
}

export default Roadmap;
