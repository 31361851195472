import './Homepage.scss';
import { ReactComponent as IconCursor } from 'assets/icon-cursor.svg';
import { ReactComponent as IconUnveil } from 'assets/thief.svg';
import { ReactComponent as IconLeftArrow } from 'assets/icon-left-arrow.svg';
import { ReactComponent as Logo2 } from 'assets/logo-2.svg';
import { ReactComponent as Map } from 'assets/map.svg';
import { ReactComponent as IconClose } from 'assets/icon-close.svg';
import { ReactComponent as BDouinLogo } from 'assets/bdouin-logo.svg';
import { ReactComponent as OurCommunityLogo } from 'assets/our-community.svg';
import { ReactComponent as PhoneCase } from 'assets/phone-case.svg';
import { ReactComponent as BDouinVerseLogo } from 'assets/bdouinverse-logo.svg';
import { ReactComponent as IconArrow } from 'assets/icon-arrow.svg';
import { ReactComponent as IconAppStore } from 'assets/icon-app-store.svg';
import { ReactComponent as IconPlayStore } from 'assets/icon-play-store.svg';
import { ReactComponent as IconStars } from 'assets/icon-stars.svg';
import { ReactComponent as BackgroundToken } from 'assets/background-token.svg';
import { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement(document.body);

function Homepage() {
  const [currentExample, setCurrentExample] = useState<number>(0);
  const [userText, setUserText] = useState<string>("Your text");
  const [openMap, setOpenMap] = useState<boolean>(false);
  const unveilText = () => {
    const hiddenText = document.getElementById("hidden-text");
    if (!hiddenText?.classList.contains("unveil")) {
      hiddenText?.classList.add("unveil");
    } else {
      hiddenText.classList.remove("unveil");
    }
  }
  const closeText = () => {
    const hiddenText = document.getElementById("hidden-text");
    if (hiddenText?.classList.contains("unveil")) {
      hiddenText.classList.remove("unveil")
    }
  }
  const inputChanged = (event: any) => {
    setUserText(event.target.value || "Your text");
  }

  const toggleModalMap = () => {
    setOpenMap(!openMap);
    if (!document.body.classList.contains("blockScrolling")) {
      document.body.classList.add("blockScrolling");
    } else {
      document.body.classList.remove("blockScrolling");
    }
  }

  const nextExample = () => {
    setCurrentExample(prev => prev < 4 ? prev + 1 : 0)
  }

  const previousExample = () => {
    setCurrentExample(prev => prev > 0 ? prev - 1 : 4)
  }

  return (
    <>
      <Modal
        isOpen={openMap}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <IconClose className='close-btn' onClick={toggleModalMap} />
        <Map className='world-map'/>
      </Modal>
      <section className="why-nft">
        <article className="sm-wrap first-reason">
          <div className="separator left-border"></div>
          <div className="reason-header">
            <div className="reason-number one-cell center right-boder">1</div>
            <h2 className="reason-title">The world is all (about) images</h2>
          </div>
          <div id="hidden-text" className="hidden-text">
            <div className="close-btn one-cell center" onClick={closeText}>
              X
            </div>
            <div className="description left-border">
              <div>
                You clicked on the icon without us having to explain it, because the symbol of the "pointer" was enough to understand the expected action. The image has replaced the words. Similarly, you will close this banner by clicking on the cross, as is commonly accepted :)
              </div>
            </div>
            <div className="unveil-btn one-cell center full-border" onClick={unveilText}>
              <IconUnveil className="unveil-icon" />
              <IconCursor className="unveil-cursor" />
            </div>
          </div>
          <div className="reason-description">
            Surrounded by screens since childhood, then by small screens growing up, the new generation has adopted the screenlife for the worst and the best.<br />
            People consume screens, represent themselves in pictures and express themselves through images.<br />
            But Images are often not accurate or true reflections, and filters have augmented - if not replaced - make-up. People compose, edit, cut…  images are malleable, at the service of school of thoughts and instant messages.<br />
            Indeed, the world now doesn’t skip a beat and is constantly generating new content! We must express everything in the moment. And what better way to summarize a thousand words than a picture?
          </div>
        </article>
        <article className="second-reason">
          <div className="sm-wrap">
            <div className="separator left-border"></div>
            <div className="reason-header">
              <div className="reason-number one-cell center right-boder">2</div>
              <h2 className="reason-title">MEME pas vrai?</h2>
            </div>
          </div>
          <div className="reason-description">
            <img src={require("assets/meme-img.png")} alt="Meme Example" />
            <div className="reason-description-text">
              In an article, an author said "(meme) They charm and amuse us. They sicken and annoy us. They bore us. They linger for a while on Facebook and then they die—or rather retreat back into the cybernetic ooze unless called upon again".<br />
              She concluded "We create and pass on the things that call to our current experiences and situations. Memes are us". But are they really us since since we never really own them after they left our computer or phone? In hindsight, one could see the mutation as it was happening before our eyes: MEME were signaling the birth of NFTs.<br />
              What made it difficult to achieve until today was the technical barrier to proving who owns or who (co)created what. This is not yet totally resolved but the hype NFTs have generated and the ever-growing funds raised on the back of the technology leads us to believe that it will be cracked.<br />
            </div>
          </div>
        </article>
        <article className="third-reason">
          <div className="sm-wrap">
            <div className="separator left-border"></div>
            <div className="reason-header">
              <div className="reason-number one-cell center right-boder">3</div>
              <h2 className="reason-title">The NFT phenomenon signals maturity</h2>
            </div>
          </div>
          <div className="reason-description-wrapper">
            <div className="reason-description">
              <span>
                Despite the craze (total transaction volume of USD 27 billion at the end of 2021!) and what most probably will end up in a big crash, one has to see in what’s happening a new paradigm that will shape the interactions between creators and their communities, for all kinds of media support.<br />
                Until today everything we post on the social networks is in fact owned by the networks, and not by the one who posts. NFT, and the underlying crypto tech, will have proven that it is possible to move to an Ownership Economy in a not so distant future. That’s an ecosystem where everybody will be able to create, co-create, own, co-own and share in the sale or the royalties generated by an original piece of digital and even physical art.<br />
                And the benefits and use cases will become obvious as we move more and more aspects of our lives into the WEB 3.0…
              </span>
              <img src={require("assets/third-reason-img.png")} alt='NFT Reason' />
            </div>
          </div>
        </article>
        <article className="our-believes">
          <h2 className="sm-wrap">We believe…</h2>
          <p>The world is divided into different <span className="full-border">communities</span>.</p>
          <p>Each community has its own codes, <span className="highlighted">
            <span>symbols</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="198.796" height="54.012" viewBox="0 0 198.796 54.012">
              <rect id="Rectangle_1428" data-name="Rectangle 1428" width="197.944" height="50.565" transform="matrix(1, -0.017, 0.017, 1, 0, 3.455)" fill="#fff" />
            </svg></span><br /> and <span className="underligned">keywords</span>.</p>
          <p>People want images that represent them :<br />
            their daily lives, their settings, their aspirations.</p>
          <p>But they also want to be able<br />
            to add their <span className="full-border">own messages</span> to these images</p>
          <p>To broadcast them, to share them, instantly <br />
            and to <span className="underligned">the widest audience</span>.</p>
          <p>And, thus, solidify the links that make them<br />
            full-fledged members of communities.</p>
        </article>
        <article className="try-your-self">
          <img src={require("assets/rock-ascent.png")} alt="Rock Ascent" />
          <span className="user-text">{userText}</span>
          <div>
            <div className='instruction'>
              <IconLeftArrow />
              <span>unleash your creativity</span>
            </div>
            <input placeholder='Tap your text here' onChange={inputChanged} />
          </div>
        </article>
      </section>
      <section className="about-us">
        <article className='who-we-are'>
          <div className='logo-2'>
            <Logo2 />
            <span>Since 2009</span>
          </div>
          <div className='our-story'>
            <div className='first-column'>
              <h2>Who we are?</h2>
              <div><BDouinLogo /></div>
              <p>Founded in 2009, in a small town in France, the BDouin is a graphic design studio<br />
                initially specialized in the<br />
                the world of comics<br />
                for the Muslim community. The first productions were shared on blogs and social pages.<br />
                The studio is directed by Norédine Allam an author recognized in Europe notably for his work on the Asterix series (the best-selling comic book in the world with more than 370 million copies sold)
              </p>
            </div>
            <div className='second-column'>
              <h2>Our community</h2>
              <div><OurCommunityLogo /></div>
              <p>The BDouin community transcends countries and languages through a shared sensibility to ethical values and faith-driven actions. It spans Muslim-majority countries such as Indonesia, Pakistan, Egypt and the Maghreb region, as well as France, where the series was born.</p>
            </div>
            <div className='third-column'>
              <h2>The BDouinverse</h2>
              <div><BDouinVerseLogo /></div>
              <p>With more than 2 million subscribers on our social pages from 20+ countries, our comics are translated in 15 languages: we are the only series uniting a wide range of communities into one giant village. The BDouinverse is our GCD, Greatest Common Drawing.</p>
            </div>
          </div>
          <button onClick={toggleModalMap}>The BDouinverse interactive map</button>
        </article>
        <article className='what-we-did'>
          <div className='first-column'>
            <p>…then BDouin made this :)</p>
            <div className='slider'>
              <div className={`example${currentExample}`}>
                <img src={require('assets/screen-1.jpg')} alt="Example 1" />
                <img src={require('assets/screen-2.jpg')} alt="Example 2" />
                <img src={require('assets/screen-3.jpg')} alt="Example 3" />
                <img src={require('assets/screen-4.jpg')} alt="Example 4" />
                <img src={require('assets/screen-5.jpg')} alt="Example 5" />
              </div>
              <PhoneCase />
            </div>
            <div className='slider-nav'>
              <IconArrow className={'slider-nav-left'} onClick={previousExample} />
              <IconArrow className={'slider-nav-right'} onClick={nextExample} />
            </div>
          </div>
          <div className='second-column'>
            <img src={require("assets/icon-app-v2.png")} alt="Rock Ascent" />
            <p>The first custom<br />
              image generator<br />
              for our community</p>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.bdouin.apps.muslimstrips&hl=en&gl=US"><IconPlayStore className='icon-playstore' /></a>
              <a href="https://apps.apple.com/us/app/bdouin-by-muslimshow/id946822771"><IconAppStore /></a>
            </div>
            <p className='app-story'>May 2019 on Playstore<br />
              March 2020 on Appstore</p>
            <p className='nb-downloads'>200K+ Downloads</p>
            <p className='evaluation'>4.6<IconStars /></p>
            <p className='nb-ratings'>3,811 ratings</p>
          </div>
        </article>
        <article className='our-stats'>
          <p className='nb-elements'>18 characters, 200 scenes, 2,000 emojis</p>
          <p className='nb-creations'>500,000</p>
          <p className='nb-creations-desc'>creations by our users</p>
          <Link target="_blank" className='button' to='/userscenes' >See our community’s work</Link>
        </article>
      </section>
      <section className="next-step">
        <BackgroundToken />
        <div>
          <p>And now,<br />
            We want to go farther<br />
            with our community</p>
          <p>Give it a ramp to take-off,<br />
            to the moon and beyond!</p>
        </div>
      </section>
    </>
  );
}

export default Homepage;
