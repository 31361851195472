import './MainLayout.scss';
import Header from './Header';
import Footer from './Footer';
import { ReactComponent as IconSubmit } from 'assets/thief.svg';
import { useEffect, useState } from 'react';

const confidentialCode = "DiNaMus"

function MainLayout(props: { children: any, className: string, hideImageBanner?: boolean }) {

  const [allowed, SetAllowed] = useState<boolean>();
  const [code, setCode] = useState<string>();
  const [hasError, setHasError] = useState<boolean>();

  useEffect(() => {
    SetAllowed(localStorage.getItem("correctCode") === confidentialCode);
  }, [allowed])

  const handleInput = (event: any) => {
    setCode(event.target.value);
  }

  const submit = () => {
    setHasError(undefined);
    if (code === confidentialCode) {
      localStorage.setItem("correctCode", confidentialCode);
      setHasError(false);
      document.location.reload();
    } else {
      setHasError(true);
    }
  }

  return (
    <div id="main" className={props.className + '_main'} key={props.className}>
      <Header className={`header__${(props.className && allowed) ? props.className : ''}`} allowed={allowed} />
      {(!props.hideImageBanner || !allowed) && (
        <img src={require("assets/bandeau-img.jpg")} alt="Bandeau" className="bandeau-img" />
      )}
      <div className={`ks-mainFrame ${props.className || ''}`}>
        <div id='mainContentScreen' className='ks-content'>
          {props.children}
        </div>
      </div>
      <Footer className={`footer__${props.className || ''}`} />
    </div>
  );
}

export default MainLayout;
