import Roadmap from "page/Roadmap";
import Team from "page/Team";
import UserScenes from "page/UserScenes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import MainLayout from "./layout/MainLayout";
import Homepage from "./page/Homepage";
import OurStory from "./page/OurStory";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} render={HomepageRender} />
        <Route path="/ourstory" exact={true} render={OurStoryRender} />
        <Route path="/roadmap" exact={true} render={RoadmapRender} />
        <Route path="/team" exact={true} render={TeamRender} />
        <Route path="/userscenes" exact={true} render={UserScenesRender} />
      </Switch>
    </Router>
  );
}

const HomepageRender = (props: any) => (
  <MainLayout className="homepage-page">
    <Homepage {...props} />
  </MainLayout>
);

const OurStoryRender = (props: any) => (
  <MainLayout className="our-story-page">
    <OurStory {...props} />
  </MainLayout>
);

const RoadmapRender = (props: any) => (
  <MainLayout className="roadmap-page" hideImageBanner >
    <Roadmap {...props} />
  </MainLayout>
);

const TeamRender = (props: any) => (
  <MainLayout className="team-page" >
    <Team {...props} />
  </MainLayout>
);

const UserScenesRender = (props: any) => (
  <MainLayout className="userscenes-page" hideImageBanner>
    <UserScenes {...props} />
  </MainLayout>
);

export default App;
