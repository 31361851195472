import './Header.scss';
import { ReactComponent as IconDoc } from 'assets/icon-doc.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Link } from 'react-router-dom';

function Header(props: { className?: string, allowed?: boolean }) {
  const { allowed, className } = props;
  return (
    <>
      <header className={className || ''}>
        <nav>
          <span className="whitpaper">
            <IconDoc />&nbsp;Whitepaper
          </span>
          <Link to="/roadmap" className="bdouin-link">Roadmap</Link>
          <span className="logo">
            <Link to="/"><Logo /></Link>
          </span>
          <Link to="/ourstory" className="roadmap-link">Our story</Link>
        </nav>
      </header>
    </>
  );
}

export default Header;
