import StoryItem from 'elements/StoryItem';
import './OurStory.scss';

function OurStory() {

  return (<div className='our-story-wrapper'>
    <div className='vertical-line'></div>
    <div className='our-story-element'>
      <StoryItem title={<>Studio 2HB<br />is born</>} date='2002'
        image={require('assets/2002-img.png')}
        text={<>In Amiens (France), Norédine Allam creates the first comic book colorization studio in Europe specialized in computer-assisted colorization.<br />Their first series, Les Poussières de I’infini, is published by Soleil.</>} />
      <StoryItem title={<>ASTERIX<br />REFOUNDATION</>} date='2006'
        image={require('assets/2006-img.png')}
        text={<>Studio 2HB wins the then-largest European comic bid ever to recolor the entire Asterix collection (the best-selling series in the world). To win the contract, Norédine developed a proprietary colorization process to work with a team that had no artistic knowledge.</>} />
      <StoryItem title={<>2HB<br />BECOMES<br />BDOUIN</>} date='2009'
        image={require('assets/2009-img.png')}
        text={<>In 2009, Studio 2HB becomes Studio BDouin and changes course. The same year, the team signs Muslim Show: the first European comic series about the life of Muslims.<br />Dargaud, publisher of The Smurfs, Garfield, Billy & Buddy, is in charge of the distribution.</>} />
      <StoryItem title={<>THE<br />FACEBOOK<br />SENSATION</>} date='2011'
        image={require('assets/2011-img.png')}
        text={<>With more than one million subscribers across two Facebook pages , the Muslim Show series becomes the most read « easi » comic in the world.<br />Some of the images posted reach several million people and are translated by fans into more than 15 languages.</>} />
      <StoryItem title={<>ADABEO<br />AVATARS</>} date='2012'
        image={require('assets/2012-img.png')}
        text={<>Creation of Adabeo, small isometric characters with with thousands appearances.<br />The same year, we launched an avatar generator with certificate of ownership for the user; a rare initiative at the time!<br />…NFT before it was cool :)</>} />
    </div>
    <div className='horizontal-line'></div>
    <div className='story-2015'>
      <StoryItem title={<>NEW<br />DESIGN</>} date='2015'
        image={require('assets/2015-img.png')}
        text={<>Norédine decides to change his graphic style and creates a new design: a mix of black silhouettes and cartoon elements.<br />It is the first time in the world of comics that a well-known series makes this kind of change.</>} />

      <div className='vertical-line'></div>
    </div>
    <div className='our-story-element-right'>
      <StoryItem title={<>FIRST<br />fundraising</>} date='2017'
        image={require('assets/2017-img.png')}
        text={<>BDouin completes its first round of financing with NEXV, an investment fund based in Luxembourg and Dubai. These new funds allow the studio to launch new series such as La Famille Foulane. In only 2 years, BDouin produces 15 books and sell more than 100,000 books in France.</>} />
      <StoryItem title={<>BDOUIN<br />STORY MAKER</>} date='2019'
        image={require('assets/2019-img.png')}
        text={<>Launch of the mobile application BDouin Story Maker and entry in the Top 3 trendiest applications of the Playstore in France. This unique application in the community enables anyone to create their own comics in BDouin style, and has been downloaded by nearly 200,000 people worldwide.</>} />
      <StoryItem title={<>AWLAD<br />SCHOOL</>} date='2021'
        image={require('assets/2021-img.png')}
        text={<>Creation of a unique software for learning Arabic, fully illustrated, interactive and voiced-over<br />by professional actors.<br />The software, currently available on the web, includes several innovative mini-applications to reinforce learning. </>} />
    </div>

    <div className='next-for-2022'>
      <span className='our-story-item__title-date__date'>2022</span>
      <span className='next-for-2022-title'>BDOUIN NFT MAKER</span>
    </div>
  </div>);
}

export default OurStory;
