import './UserScenes.scss';

function UserScenes() {

  return (<>
    <iframe src='https://d2tk84rt5p4ca.cloudfront.net' title='User Scenes' />
  </>);
}

export default UserScenes;
